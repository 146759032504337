import React, { useState, useEffect } from "react"

const JsFontSize = ({ render }) => {
  const [width, setWidth] = useState(400)
  const [height, setHeight] = useState(400)
  const [emsfontsize, setFontsize] = useState(0.8)

  useEffect(() => {
    const fontsize =
      width < 800
        ? 0.6
        : width < 850
        ? 0.65
        : width < 1650
        ? 0.7
        : width < 1750
        ? 0.75
        : width < 1800
        ? 0.775
        : width < 1850
        ? 0.8
        : 0.85

    setFontsize(fontsize)
  }, [width])

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)
    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  }, [])

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  return (
    <>
      {render({
        emsfontsize,
        width,
        height,
      })}
    </>
  )
}

export default JsFontSize
