import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css, keyframes } from "@emotion/react"
import { graphql, StaticQuery, Link } from "gatsby"
import LineSVG from "../../images/icons/Line7.svg"
import MapSVG from "../../images/icons/bx-map.svg"
import CheckSVG from "../../images/icons/bx-check-double.svg"
import Img from "gatsby-image"
import JsFontSize from "../JsFontSize"
import { orangeButtonColorsTransparentBg } from "../shared"

const wrapperCommon = css`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  text-decoration: none;
  z-index: 2;
  position: relative;
  background-color: #fff;
  border: 0;
  height: 100%;
`

const Wrapper = styled.div`
  ${wrapperCommon}
`

const HightPartLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
`

const WrapperLink = styled(Link)`
  ${wrapperCommon}
`

const DateAndOnOffWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #8c97ac;
    font-weight: 600;
    margin: 0;
    font-size: 13px;
  }
`

const OnlineOffline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: ${(props) => (props.online ? "#E9F9F4" : "#E3EBFF;")};
  color: ${(props) => (props.online ? "#04AA77" : "#6C8DE0;")};
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  margin-left: auto;
`

const EName = styled.h4`
  color: #1d293f;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.6;
  margin: 10px 0 0 0;
`

const Tags = styled.p`
  color: ${(props) => (props.relevant ? "#8F96A1" : "#FD7114;")};
  margin: 0 9px 9px 0;
  font-weight: 600;
  font-size: 13px;
`

const PriceCaption = styled.p`
  color: #8f96a1;
  font-weight: 600;
  font-size: 13px;
`
const Price = styled.p`
  color: #fd7114;
  font-weight: 600;
  font-size: 13px;
`

const ListWithLeftIcons = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;

  grid-column-gap: 14px;
  border-radius: 48px;
  border: 0;
  cursor: pointer;
  align-content: center;
  img {
    max-height: 16px;
    justify-self: center;
  }
`

const ListParWithLeftIcons = styled.p`
  font-size: 13px;
  color: ${(props) => props.color};
  margin: 0;
  text-align: initial;
  font-weight: ${(props) => (props.color === "#FD7114" ? "600" : "400")};
`

const Discount = styled.p`
  color: #f5222d;
  font-size: 11px;
  font-weight: 600;
  margin: 0 0 2px auto;
`

const css2 = css`
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.58em 1.17em;
  background-color: ${(props) =>
    props.orangeButton ? "#FD7114" : "transparent"};
  color: ${(props) => (props.orangeButton ? "#fff" : "#FD7114")};
  border-radius: 6px;
  border: 1px solid #fd7114;
  color: #fd7114;
  font-size: 1.17em;
  text-decoration: none;
  font-weight: 500;
`

export const CardButton = styled(Link)`
  ${css2};
  ${orangeButtonColorsTransparentBg};
`

export const CardButtonHref = styled.a`
  cursor: ${(props) =>
    props.absentLink ? "wait" : props.unactive ? "default" : "pointer"};

  pointer-events: ${(props) => (props.unactive ? "none" : "auto")};
  filter: ${(props) => (props.unactive ? "grayscale(0.8)" : "grayscale(0)")};

  ${css2};
  ${orangeButtonColorsTransparentBg};

  @media (max-width: 640px) {
    font-size: 16px;
    color: #fff;
    background-color: #fd7114;

    :hover {
      color: #fff;
    }
  }
`

const EDescription = styled.p`
  color: #525151;
  font-size: 1em;
  font-weight: 400;
  margin: 10px 0;

  @media (max-width: 1300px) {
    font-size: 12px;
  }
`

const myMonths = [
  "січня",
  "лютого",
  "березня",
  "квітня",
  "травня",
  "червня",
  "липня",
  "серпня",
  "вересня",
  "жовтня",
  "листопада",
  "грудня",
]

const a1 = keyframes`
    0% {
      filter: opacity(0);
    }
    
    3% {
      filter: opacity(0.9);
    }

    100% {
      filter: opacity(1);
    }
`

const a2 = keyframes`
    0% {
      filter: opacity(1);
    }

    97% {
      filter: opacity(0.9);
    }

    100% {
      filter: opacity(0);
    }
`

const a3 = keyframes`
    0% {
      filter: opacity(0);
    }
    
    97% {
      filter: opacity(0.9);
    }

    100% {
      filter: opacity(1);
    }
`

const BgCircle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${a3} 300ms ease-in-out forwards;

  circle {
    stroke-width: ${(props) => `${props.circleWidth * 30}px`};
    stroke-width: 70px;
    fill: #ea640c;
    stroke-dasharray: 1;

    transform: rotate(-90deg);
    position: absolute;
  }

  svg {
    display: block;
    margin: auto;
    overflow: visible;
    position: relative;
    width: 1px;
    height: 1px;
    margin: 0;
    transform: scale(10.5);
  }
`

const BgCircleWrapper = styled.div`
  position: relative;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  padding: 2px;

  circle {
    stroke: #fff;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s ease-in-out,
      stroke 0.1s cubic-bezier(0.49, -0.24, 0.74, -0.37);
  }

  svg {
    filter: opacity(0);
    animation: ${a2} 1000ms ease-in-out forwards;
  }

  :hover {
    circle {
      stroke-dashoffset: 1;
      stroke: #ea640c;
      transition: stroke-dashoffset 1s ease-in-out,
        stroke 1.3s cubic-bezier(0.49, -0.24, 0.74, -0.37);
    }

    svg {
      filter: opacity(1);
      animation: ${a1} 500ms ease-in-out forwards;
    }
  }
`

const Component = React.memo(
  ({
    imgs,
    Id,
    courseImageName,
    date,
    description,
    discount,
    link,
    name,
    online,
    pricePerCourse,
    pricePerMonth,
    relevant,
    tags,
    featuresList,
    buttonCaption,
    orangeButton,
    startCourseDate,
    priceString,
    circleWidth,
  }) => {
    const [rendered, setRendered] = useState(false)

    const img = imgs.allFile.edges.find((i) => i.node.name === courseImageName)

    useEffect(() => {
      setRendered(true)
    })

    if (relevant) {
      return (
        <BgCircleWrapper>
          {rendered && (
            <BgCircle circleWidth={circleWidth}>
              <svg viewbox={`0 0 0 0`}>
                <circle cx="0" cy="0" r="30" pathLength="1" />
              </svg>
            </BgCircle>
          )}

          <Wrapper>
            <HightPartLink to={`/course/${link}`}>
              <DateAndOnOffWrapper>
                {startCourseDate && <p>Старт {startCourseDate}</p>}

                {relevant && (
                  <OnlineOffline online={online}>
                    {online && "Online"}
                    {!online && "Offline"}
                  </OnlineOffline>
                )}
              </DateAndOnOffWrapper>
              <EName>{name}</EName>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                `}
              >
                {tags.map((i) => (
                  <Tags relevant={relevant} key={`tag${name}${i}`}>
                    #{i}
                  </Tags>
                ))}
              </div>
              <img
                src={LineSVG}
                css={css`
                  height: 1px;
                `}
              />

              {priceString ? (
                <PriceCaption>{priceString}</PriceCaption>
              ) : (
                <>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    `}
                  >
                    <PriceCaption>Ціна за місяць</PriceCaption>
                    <Price>₴{pricePerMonth}/місяць</Price>
                  </div>
                  <img
                    src={LineSVG}
                    css={css`
                      height: 1px;
                    `}
                  />
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    `}
                  >
                    <PriceCaption>Ціна за курс</PriceCaption>
                    <Price>₴{pricePerCourse}/курс</Price>
                  </div>

                  {discount && <Discount>знижка {discount}</Discount>}

                  <img
                    src={LineSVG}
                    css={css`
                      height: 1px;
                    `}
                  />
                </>
              )}

              {featuresList && (
                <>
                  {featuresList.map((i, index) => (
                    <ListWithLeftIcons key={`${Id}${i.icon}${index}`}>
                      {i.icon === "checkmark" && (
                        <div>
                          <img src={CheckSVG} />
                        </div>
                      )}
                      {i.icon === "location" && (
                        <div>
                          <img src={MapSVG} />
                        </div>
                      )}

                      <ListParWithLeftIcons color={i.color}>
                        {i.text}
                      </ListParWithLeftIcons>
                    </ListWithLeftIcons>
                  ))}
                </>
              )}
            </HightPartLink>

            {buttonCaption && (
              <div
                css={css`
                  padding-top: 30px;
                  margin-top: auto;
                `}
              >
                <CardButton orangeButton={orangeButton} to={`/course/${link}`}>
                  {buttonCaption}{" "}
                </CardButton>
              </div>
            )}
          </Wrapper>
        </BgCircleWrapper>
      )
    } else {
      return (
        <BgCircleWrapper>
          {rendered && (
            <BgCircle circleWidth={circleWidth}>
              <svg viewbox={`0 0 0 0`}>
                <circle cx="0" cy="0" r="30" pathLength="1" />
              </svg>
            </BgCircle>
          )}

          <Wrapper>
            <HightPartLink to={`/course/${link}`}>
              <DateAndOnOffWrapper>
                <p>{startCourseDate && <p>Завершились {startCourseDate}</p>}</p>

                {relevant && (
                  <OnlineOffline online={online}>
                    {online && "Online"}
                    {!online && "Offline"}
                  </OnlineOffline>
                )}
              </DateAndOnOffWrapper>
              <EName>{name}</EName>

              <EDescription>{description.substring(0, 70)}...</EDescription>

              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                `}
              >
                {tags.map((i) => (
                  <Tags relevant={relevant} key={`tag${name}${i}`}>
                    #{i}
                  </Tags>
                ))}
              </div>

              {img && (
                <Img
                  durationFadeIn={200}
                  fluid={{
                    ...img.node.childImageSharp.fluid,
                    aspectRatio: 256 / 122,
                  }}
                  css={css`
                    border-radius: 4px;
                    margin-top: auto;

                    img {
                      object-fit: cover !important;
                    }
                  `}
                />
              )}
            </HightPartLink>
          </Wrapper>
        </BgCircleWrapper>
      )
    }
  },
  (prev, next) => true
)

export const VacancyCard = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "course-image" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  pngCompressionSpeed: 1
                  quality: 70
                  srcSetBreakpoints: [300]
                  maxWidth: 300
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(imgs) => (
      <JsFontSize
        render={({ emsfontsize }) => {
          const circleWidth = emsfontsize * 3
          return <Component imgs={imgs} circleWidth={circleWidth} {...props} />
        }}
      />
    )}
  />
)
