import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { SharedDividerHalf, SharedH3 } from "../components/shared"
import CoursesTemplate from "../templates/courses"

const Wrapper = styled.div`
  margin: auto;
  max-width: 107em;
  padding: 0 2em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

export default ({ data }) => {
  return (
    <>
      <SEO />

      <Helmet
        title="Курси"
        meta={[
          {
            name: "description",
            content: "",
          },
        ]}
      >
        <html lang="uk" />
      </Helmet>

      <Header />

      <SharedDividerHalf />

      <Wrapper>
        <SharedH3
          as="h1"
          css={css`
            color: #111;
            padding: 0;
            margin: 0;
            text-align: initial;
          `}
        >
          Наші курси
        </SharedH3>
        <p
          css={css`
            color: #8f96a1;
            font-weight: 400;
            font-size: 16px;
            margin: 1em 0 0 0;
            line-height: 2;
          `}
        >
          Кожен курс ми постійно вдосконалюємо, прислухаємось до ваших відгуків
          і потреб
        </p>

        <SharedDividerHalf />
        <CoursesTemplate />
      </Wrapper>

      <Footer />
    </>
  )
}
